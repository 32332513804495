import React from 'react';
import styles from './CalendarModal.module.css'; // Importa o CSS Module

const CalendarModal = ({ events, closeModal }) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  return (
    <div
      className={styles.modalBackdrop}
      role="presentation"
      onClick={closeModal}
      onKeyDown={handleKeyDown}
    >
      <div
        className={styles.modalContent}
        role="dialog"
        aria-modal="true"
        tabIndex={-1}
      >
        <h3>Eventos</h3>
        <ul>
          {events.map((event, index) => (
            <li key={index} className={styles.eventItem}>
              <strong>Início:</strong>{' '}
              {`${event.start.day}/${event.start.month}/${event.start.year} - ${event.start.time}`}
              <br />
              <strong>Término:</strong>{' '}
              {`${event.end.day}/${event.end.month}/${event.end.year} - ${event.end.time}`}
              <br />
              {event.description}
            </li>
          ))}
        </ul>
        <button onClick={closeModal} className={styles.closeButton}>
          Fechar
        </button>
      </div>
    </div>
  );
};

export default CalendarModal;
