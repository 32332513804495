import React from 'react';
import styles from './CalendarDays.module.css'; // Importa o CSS Module

const CalendarDays = ({ currentDate, getEventsForDay, handleDayClick }) => {
  const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const startDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  ).getDay();
  const daysInMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  ).getDate();

  const renderDays = () => {
    const days = [];

    // Preenche os dias vazios antes do primeiro dia do mês
    for (let i = 0; i < startDayOfMonth; i++) {
      days.push(<div className={styles.empty} key={`empty-${i}`} />);
    }

    // Preenche os dias do mês
    for (let i = 1; i <= daysInMonth; i++) {
      const events = getEventsForDay(i);
      days.push(
        <button
          className={`${styles.day} ${events.length ? styles.highlighted : ''}`}
          key={i}
          onClick={() => handleDayClick(i)}
        >
          {i}
        </button>,
      );
    }
    return days;
  };

  return (
    <>
      <div className={styles.daysOfWeek}>
        {daysOfWeek.map((day) => (
          <div key={day} className={styles.dayName}>
            {day}
          </div>
        ))}
      </div>
      <div className={styles.days}>{renderDays()}</div>
    </>
  );
};

export default CalendarDays;
