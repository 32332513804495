import React from 'react';
import styles from './CalendarHeader.module.css'; // Importa o CSS Module

const CalendarHeader = ({
  currentDate,
  handlePrevMonth,
  handleNextMonth,
  setSelectedYear,
}) => {
  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value, 10);
    setSelectedYear(newYear);
  };

  return (
    <div className={styles.header}>
      <button onClick={handlePrevMonth} className={styles.headerButton}>
        ←
      </button>

      <select
        value={currentDate.getFullYear()}
        onChange={handleYearChange}
        className={styles.customSelect}
      >
        {/* Exibe a lista de anos */}
        {[...Array(10)].map((_, i) => (
          <option key={i} value={currentDate.getFullYear() - 5 + i}>
            {currentDate.toLocaleString('default', { month: 'long' })}{' '}
            {currentDate.getFullYear() - 5 + i}
          </option>
        ))}
      </select>

      <button onClick={handleNextMonth} className={styles.headerButton}>
        →
      </button>
    </div>
  );
};

export default CalendarHeader;
