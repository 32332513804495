import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CalendarHeader from './CalendarHeader';
import CalendarDays from './CalendarDays';
import CalendarModal from './CalendarModal';
import styles from './Calendar.module.css';

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDayEvents, setSelectedDayEvents] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Acessa todos os subrequests do Redux
  const querystringResults = useSelector(
    (state) => state.querystringsearch.subrequests,
  );

  // Extrai e filtra apenas os itens que são eventos
  const eventItems = Object.values(querystringResults)
    .flatMap((subrequest) => subrequest.items || [])
    .filter((item) => item.type_title === 'Evento'); // Filtra por tipo de evento

  const getEventsForDay = (day) => {
    return eventItems.filter((event) => {
      const eventDate = new Date(event.start);
      return (
        eventDate.getDate() === day &&
        eventDate.getMonth() === currentDate.getMonth() &&
        eventDate.getFullYear() === currentDate.getFullYear()
      );
    });
  };

  const handleDayClick = (day) => {
    const events = getEventsForDay(day);
    if (events.length) {
      setSelectedDayEvents(events);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => setIsModalOpen(false);

  const handlePrevMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1),
    );
  };

  const setSelectedYear = (year) => {
    setCurrentDate(new Date(year, currentDate.getMonth(), 1));
  };

  return (
    <div className={styles.calendar}>
      <CalendarHeader
        currentDate={currentDate}
        handlePrevMonth={handlePrevMonth}
        handleNextMonth={handleNextMonth}
        setSelectedYear={setSelectedYear}
      />
      <CalendarDays
        currentDate={currentDate}
        getEventsForDay={getEventsForDay}
        handleDayClick={handleDayClick}
      />
      {isModalOpen && (
        <CalendarModal events={selectedDayEvents} closeModal={closeModal} />
      )}
    </div>
  );
};

export default Calendar;
